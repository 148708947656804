import React, { useState, useEffect } from "react";
import Row from "./row";
import SumRow from "./sumRow";
const Table = (props) => {
  const [sums, setSums] = useState({ rev: 0, count: 0, coupons: 0 });
  const renderRows = () => {
    const rows = [];
    props.totals.map((total) => {
      rows.push(
        <Row
          store={total.store}
          revenue={total.totals.revenueConverted}
          orders={total.totals.orderCount}
          coupons={total.totals.couponsUsed}
          products={total.totals.productTotals}
        />
      );
    });
    return rows;
  };

  return (
    <div style={{ display: props.display ? "block" : "none" }}>
      <table>
        <thead>
          <tr>
            <th>TRGOVINA</th>
            <th>PROMET</th>
            <th>ST. NAROCIL</th>
            <th>UPORABLJENI KUPONI</th>
            <th>SPEND</th>
            <th>ROAS</th>
            <th>CPP</th>
            <th>COMB</th>
            <th>C %</th>
            <th>HairVital</th>
            <th>HV %</th>
            <th>HairMask</th>
            <th>HM %</th>
          </tr>
        </thead>
        <tbody>{renderRows()}</tbody>
        <tfoot>
          <SumRow
            rev={props.calculated.rev}
            count={props.calculated.count}
            coupons={props.calculated.coupons}
            products={props.calculated.products}
          />
        </tfoot>
      </table>
    </div>
  );
};

export default Table;
