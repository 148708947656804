import React from "react";

const Year = (props) => {
  const fillYear = () => {
    const options = [];
    const year = new Date(Date.now()).getFullYear();
    let yearWhile = year - 2;
    while (yearWhile <= year) {
      options.push(<option value={yearWhile}>{yearWhile}</option>);
      yearWhile++;
    }
    return options;
  };
  const onChange = (e) => {
    /* const oldState = {...props.state};
        oldState.year=e.target.value;
          props.setState(oldState); */
    props.setState(e.target.value);
  };
  return (
    <div className="select-container">
      <p className="select-label">Leto</p>
      <select
        onChange={onChange}
        defaultValue={new Date(Date.now()).getFullYear()}
      >
        {fillYear()}
      </select>
    </div>
  );
};

export default Year;
