import React, { useState } from "react";

const Row = (props) => {
  const [spend, setSpend] = useState(props.spend);
  console.log(props);
  return (
    <tr>
      <td>
        {props.video == null || props.video == "undefined"
          ? "Brez videa"
          : props.video}
      </td>
      <td>
        {(props.total / props.rate).toFixed(2)} {props.currency}
      </td>
      <td>{props.orders}</td>
      <td>{props.spend.toFixed(2)}</td>
      <td>{(props.total / props.rate / props.spend).toFixed(2)}</td>
      <td>{(props.spend / props.orders).toFixed(2)}</td>
    </tr>
  );
};

export default Row;
