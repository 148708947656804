const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH":
      return {
        ...state,
        stores: action.payload,
        /* selectedStore: action.payload[0].ID,
        currency: action.payload[0].currency, */
      };
    case "SELECT":
      const val = action.payload.split("|");
      localStorage.setItem("selectedStore", val[0]);
      localStorage.setItem("currency", val[1]);
      return {
        ...state,
        selectedStore: val[0],
        currency: val[1],
      };
    default:
      return state;
  }
};

export default reducer;
