import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import StoreContext from "../context/storeContext";
import CurrencyContext from "../context/currency/currencyContext";
const Navbar = () => {
  const stores = [];
  const storeContext = useContext(StoreContext);
  const currencyContext = useContext(CurrencyContext);
  const fetchCurrencies = async () => {
    try {
      const response = await fetch(
        /* "http://localhost:5000/api/valute" */ "https://analitika.mpdiet.eu/api/valute",
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
          },
        }
      );
      const data = await response.json();

      currencyContext.fetchCurrencies(data.result);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchStores = async () => {
    try {
      const response = await fetch(
        /* "http://localhost:5000/api/stores" */ "https://analitika.mpdiet.eu/api/stores",
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
          },
        }
      );
      const data = await response.json();
      storeContext.fetchStores(data.result);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (sessionStorage.getItem("accessToken")) {
      fetchStores();
      fetchCurrencies();
    }
  }, []);
  const addOptions = () => {
    storeContext.state.stores.map((e) => {
      stores.push(
        <option
          value={`${e.ID}|${e.currency}`}
          selected={
            e.ID == localStorage.getItem("selectedStore") ? true : false
          }
        >
          {e.name}
        </option>
      );
    });
  };
  addOptions();

  const onChange = (e) => {
    storeContext.selectStore(e.target.value);
  };

  const loadBtn = () => {
    fetchStores();
    fetchCurrencies();
  };

  return (
    <div className="navbar">
      <Link to="/totals">Totals </Link>
      <Link to="/shop-stats">Po trgovinah </Link>
      <Link to="/add-store">Dodaj trgovino</Link>
      <Link to="/valute">Valute</Link>
      <div className="nav-select-container">
        <p className="nav-label">IZBERI TRGOVINO</p>
        <select className="store-select" onChange={onChange}>
          {stores}
        </select>
        <p onClick={loadBtn} className="nav-load-p">
          Osvezi
        </p>
      </div>
    </div>
  );
};

export default Navbar;
