import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const AddStore = () => {
  const [storeName, setStoreName] = useState(null);
  const [clientKey, setClientKey] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);
  const [errorAlert, setErrorAlert] = useState(null);
  const [storeUrl, setStoreUrl] = useState(null);
  const [success, setSuccess] = useState(null);
  const [currency, setCurrency] = useState(null);
  const navigate = useNavigate();
  const [currs, setCurrs] = useState([]);
  const fetchCurrencies = async () => {
    try {
      const response = await fetch(
        /* "http://localhost:5000/api/valute" */ "https://analitika.mpdiet.eu/api/valute",
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
          },
        }
      );
      const data = await response.json();
      console.log(data);
      setCurrs(data.result);
    } catch (error) {
      console.log(error);
    }
  };
  const handleName = (e) => {
    setStoreName(e.target.value);
  };
  const handleKey = (e) => {
    setClientKey(e.target.value);
  };
  const handleSecret = (e) => {
    setClientSecret(e.target.value);
  };
  const handleUrl = (e) => {
    setStoreUrl(e.target.value);
  };
  const handleCurrency = (e) => {
    setCurrency(e.target.value);
  };

  useEffect(() => {
    if (!sessionStorage.getItem("accessToken")) {
      navigate("/");
    }
    fetchCurrencies();
  }, []);
  const sendStore = async () => {
    try {
      console.log(storeName, clientKey, clientSecret, storeUrl, currency);
      if (!storeName || !clientSecret || !clientKey || !storeUrl || !currency) {
        setErrorAlert("Izpolni vsa polja!");
        return;
      }
      const reqBody = {
        name: storeName,
        key: clientKey,
        secret: clientSecret,
        url: storeUrl,
        currency: currency,
      };
      console.log(reqBody);
      const response = await fetch(
        /* "http://localhost:5000/api/stores" */ "https://analitika.mpdiet.eu/api/stores",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",

            Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
          },
          body: JSON.stringify(reqBody),
        }
      );
      const parsed = await response.json();
      console.log(parsed.success);
      /***************************************/

      /*********************************************** */

      if (parsed.success == false) {
        console.log(parsed.msg);
        setErrorAlert(parsed.msg);
      } else {
        setSuccess("TRGOVINA UPESNO DODANA");
        setErrorAlert(null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const renderSelect = (data) => {
    const options = [];
    data.map((e) => {
      options.push(<option value={e.name}>{e.name}</option>);
    });
    return <select onClick={handleCurrency}>{options}</select>;
  };

  return (
    <div className="add-store">
      <h1>DODAJ TRGOVINO</h1>
      <div className="form-div add-store-container">
        <div
          className="err-div"
          style={{ display: errorAlert == null ? "none" : "block" }}
        >
          {errorAlert}
        </div>
        <div
          className="success-div"
          style={{ display: success == null ? "none" : "block" }}
        >
          {success}
        </div>
        <div className="input-container">
          <p className="input-label">Ime trgovine</p>
          <input type="text" onChange={handleName}></input>
        </div>
        <div className="input-container">
          <p className="input-label">Customer key</p>
          <input type="text" onChange={handleKey}></input>
        </div>
        <div className="input-container">
          <p className="input-label">Customer secret</p>
          <input type="text" onChange={handleSecret}></input>
        </div>
        <div className="input-container">
          <p className="input-label">URL trgovine</p>
          <input type="text" onChange={handleUrl}></input>
        </div>
        <p className="example">
          Primer: <i>https://wordpress-627600-2399226.cloudwaysapps.com</i>
        </p>
        <div className="input-container">
          <p className="input-label">Valuta</p>
          {renderSelect(currs)}
        </div>

        <span
          className="btn"
          onClick={() => {
            sendStore();
          }}
        >
          Dodaj trgovino
        </span>
      </div>
    </div>
  );
};

export default AddStore;
