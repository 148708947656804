import React, { useEffect, useState } from "react";
import Adset from "./adsetBeta";

const Campaign = (props) => {
  const [adsets, setAdsets] = useState(props.adsets);
  const [campaign, setCampaign] = useState(props.campaign);
  const [showAdset, setShowAdset] = useState(false);

  useEffect(() => {}, []);
  const onClickCam = () => {
    setShowAdset(!showAdset);
  };
  const renderAdsets = () => {
    const adsetComponents = [];
    adsets.map((e) => {
      /* console.log(e); */
      adsetComponents.push(
        <Adset
          adset={e.adset}
          videos={e.videos}
          rate={props.rate}
          display={props.display}
          displayAdset={showAdset}
        />
      );
    });
    return adsetComponents;
  };
  return (
    <div className="campaign">
      <div className="campaign-name" onClick={onClickCam}>
        {campaign == null || campaign == "undefined"
          ? "Brez kampanje"
          : campaign}
      </div>
      {renderAdsets()}
    </div>
  );
};

export default Campaign;
