import { React, useReducer } from "react";
import StoreContext from "./storeContext";
import StoreReducer from "./storeReducer";

const StoreState = (props) => {
  const [state, dispatch] = useReducer(StoreReducer, {
    stores: [],
    selectedStore: localStorage.getItem("selectedStore") || null,
    currency: localStorage.getItem("currency") || null,
  });

  const fetchStores = (data) => {
    dispatch({ type: "FETCH", payload: data });
  };

  const selectStore = (data) => {
    dispatch({ type: "SELECT", payload: data });
  };

  return (
    <StoreContext.Provider value={{ state, fetchStores, selectStore }}>
      {props.children}
    </StoreContext.Provider>
  );
};

export default StoreState;
