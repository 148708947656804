import React from "react";
import Row from "./rowBeta";
const TableBody = (props) => {
  console.log(props);
  const fillRows = () => {
    const rows = [];
    props.result.map((e) => {
      let spend = 0;
      e.orders.map((o) => {
        console.log(o.spend);
        spend = spend + o.spend;
      });
      rows.push(
        <Row
          video={e.video}
          total={e.total}
          orders={e.orders.length}
          currency={"EUR"}
          rate={props.rate}
          spend={spend}
        />
      );
    });
    return rows;
  };

  return (
    <div className={props.display == true ? "table-container" : "hide"}>
      <table>
        <thead>
          <tr>
            <th>Video</th>
            <th>Vrednost narocil</th>
            <th>Stevilo narocil</th>
            <th>Spend</th>
            <th>ROAS</th>
            <th>CPP</th>
          </tr>
        </thead>
        <tbody>{fillRows()}</tbody>
        <tfoot>
          <tr>
            <td>Skupaj</td>
            <td>
              {(props.totals.sumAll / props.rate).toFixed(2)} {props.currency}
            </td>
            <td>{props.totals.countAll} </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default TableBody;
