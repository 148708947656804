import React, { useState, useEffect } from "react";
import TableBody from "./tbodyBeta";
const Adset = (props) => {
  console.log(props);
  const [videos, setVideos] = useState([]);
  const [showTable, setShowTable] = useState(false);

  const filterOrders = (arr) => {
    const videoNames = [];
    const final = [];
    arr.map((e) => {
      if (e.video == "undefined") {
        e.video = null;
      }
      if (!videoNames.includes(e.video)) {
        videoNames.push(e.video);
        final.push({ video: e.video, orders: [], total: 0 });
      }
    });
    videoNames.map((i) => {
      const iIndex = videoNames.indexOf(i);
      arr.map((q) => {
        if (q.video == i) {
          final[iIndex].orders.push(q);
          final[iIndex].total = final[iIndex].total + parseFloat(q.total);
        }
      });
    });
    return final;
  };

  const setTotals = (arr) => {
    let sumVid = 0;
    let countVid = 0;
    let sumAll = 0;
    let countAll = 0;
    arr.map((e) => {
      if (e.video == "undefined" || e.video == null) {
        sumVid = sumVid;
      } else {
        sumVid = sumVid + parseFloat(e.total);
        countVid++;
      }
      sumAll = sumAll + parseFloat(e.total);
      countAll++;
    });
    return { sumVid, countVid, sumAll, countAll };
  };
  useEffect(() => {
    setVideos(filterOrders(props.videos));
  }, []);
  const onClickAdset = () => {
    setShowTable(!showTable);
  };

  return (
    <div
      className="adset"
      style={{ display: props.displayAdset ? "block" : "none" }}
    >
      <div className="adset-name" onClick={onClickAdset}>
        {props.adset == null || props.adset == "undefined"
          ? "Brez adseta"
          : props.adset}
      </div>
      <TableBody
        result={videos}
        display={showTable}
        currency={"EUR"}
        rate={props.rate}
        totals={setTotals(props.videos)}
      />
    </div>
  );
};

export default Adset;
