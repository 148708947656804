import React from "react";

const Day = (props) => {
  const fillSelect = (length) => {
    const dnevi = Array.from({ length: length }, (x, i) => i + 1);

    const options = [];
    dnevi.map((e) => {
      options.push(<option value={e}>{e}</option>);
    });
    return options;
  };

  const onChange = (e) => {
    props.setState(e.target.value);
  };
  return (
    <div className="select-container">
      <p className="select-label">Dan</p>
      <select onChange={onChange} defaultValue={new Date(Date.now()).getDate()}>
        {fillSelect(31)}
      </select>
    </div>
  );
};

export default Day;
