import { React, useReducer } from "react";
import CurrencyContext from "./currencyContext";
import CurrencyReducer from "./currencyReducer";

const CurrencyState = (props) => {
  const [state, dispatch] = useReducer(CurrencyReducer, {
    currencies: [],
  });

  const fetchCurrencies = (data) => {
    dispatch({ type: "FETCH", payload: data });
  };

  return (
    <CurrencyContext.Provider value={{ state, fetchCurrencies }}>
      {props.children}
    </CurrencyContext.Provider>
  );
};

export default CurrencyState;
