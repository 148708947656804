import React, { useState } from "react";

const Row = (props) => {
  const [spend, setSpend] = useState(0);
  const handleChange = (e) => {
    setSpend(e.target.value);
  };
  return (
    <tr>
      <td>
        {props.video == null || props.video == "undefined"
          ? "Brez videa"
          : props.video}
      </td>
      <td>
        {(props.total / props.rate).toFixed(2)} {props.currency}
      </td>
      <td>{props.orders}</td>
      <td>
        <input
          className="tbl-input-spend"
          type="number"
          onChange={handleChange}
        ></input>
      </td>
      <td>{(props.total / props.rate / spend).toFixed(2)}</td>
      <td>{(spend / props.orders).toFixed(2)}</td>
    </tr>
  );
};

export default Row;
