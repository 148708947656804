import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [loginError, setLE] = useState(null);

  const navigate = useNavigate();
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  const handlePassword = (e) => {
    setPassword(e.target.value);
  };
  const onSubmitLogin = async () => {
    if (email && password) {
      try {
        const reqBody = { email, password };
        const response = await fetch(
          /* "http://localhost:5000/api/login" */ "https://analitika.mpdiet.eu/api/login",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(reqBody),
          }
        );
        const data = await response.json();
        console.log(data);
        if (data.msg || !data.token) {
          setLE(true);
          return;
        }
        sessionStorage.setItem("accessToken", data.token);
        setLE(false);
        navigate("/totals");
      } catch (error) {
        console.log(error);
        setLE(true);
      }
    }
  };
  return (
    <div className="login">
      <h2>Prijavi se</h2>
      <p>Uporabnisko ime:</p>
      <input type="email" onChange={handleEmail}></input>
      <p>Geslo:</p>
      <input type="password" onChange={handlePassword}></input>
      <span className="btn" onClick={onSubmitLogin}>
        Prijava
      </span>
      <div
        className="err-div-login"
        style={{ display: loginError ? "block" : "none" }}
      >
        Vnesite prave podatke
      </div>
    </div>
  );
};

export default Login;
