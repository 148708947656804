import React, { useState, useEffect } from "react";
import Table from "../components/totals/table";
import Select from "../components/selectbox/selectb";
import { useNavigate } from "react-router-dom";
const TotalsPage = () => {
  const navigate = useNavigate();

  const formatDate = (date) => {
    const initial = new Date(date);
    const final = `${initial.getFullYear()}-${
      initial.getMonth() + 1
    }-${initial.getDate()}`;
    return final;
  };

  const initState = (a) => {
    const today = new Date(Date.now());
    const state = {};
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    const day = today.getDate();
    switch (a) {
      case "D":
        return day;
      case "M":
        return month;
      case "Y":
        return year;
    }
    /* return state; */
  };
  /******************************STATE***************************************/
  const [totals, setTotals] = useState([]);
  const [start, setstart] = useState(formatDate(Date.now()));
  const [end, setEnd] = useState(formatDate(Date.now()));
  const [errorState, setErrorState] = useState(null);
  /******************DISPLAY STATE*******************************************************/
  const [dayDiv, setDayDiv] = useState(true);
  const [periodDiv, setPeriodDiv] = useState(false);
  const [displayTable, setDisplayTable] = useState(false);
  const [loading, setLoading] = useState(true);
  /************************************************************************************/
  /* const [startDate, setStartDate] = useState(initState());
  const [endDate, setEndDate] = useState(initState()); */

  /*********************SELECT START***************************************/
  const [endDateSelected, setEndDateSelected] = useState(false);
  const [dayStart, setDayStart] = useState(initState("D"));
  const [monthStart, setMonthStart] = useState(initState("M"));
  const [yearStart, setYearStart] = useState(initState("Y"));
  /***************************SELECT END************************************************/
  const [dayEnd, setDayEnd] = useState(initState("D"));
  const [monthEnd, setMonthEnd] = useState(initState("M"));
  const [yearEnd, setYearEnd] = useState(initState("Y"));
  /**************************************************************************/
  const toggleView = () => {
    setDayDiv(!dayDiv);
    setPeriodDiv(!periodDiv);
    setEndDateSelected(!endDateSelected);
  };
  const fetchTotals = async () => {
    try {
      setLoading(true);
      setDisplayTable(false);
      let perStart = `${yearStart}-${
        monthStart.lengt > 1 ? monthStart : "0" + monthStart
      }-${dayStart.lengt > 1 ? dayStart : "0" + dayStart}`;
      let perEnd = `${yearEnd}-${
        monthEnd.lengt > 1 ? monthEnd : "0" + monthEnd
      }-${dayEnd.lengt > 1 ? dayEnd : "0" + dayEnd}`;
      if (!endDateSelected) {
        perEnd = perStart;
      }

      const response = await fetch(
        /* `http://localhost:5000/api/orders/totals?start=${perStart}&end=${perEnd}`, */
        `https://analitika.mpdiet.eu/api/orders/totals?start=${perStart}&end=${perEnd}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
          },
        }
      );
      const parsed = await response.json();
      console.log(parsed);
      setTotals(parsed.result);
      setLoading(false);
      setDisplayTable(true);
    } catch (err) {
      console.log(err);
      setLoading(false);
      setDisplayTable(false);
      setTotals([]);
    }
  };
  const calculateTotals = (arr) => {
    let totalRev = 0;
    let totalCount = 0;
    let totalCoupons = 0;
    //Product Totals
    let totalComb = 0;
    let totalVital = 0;
    let totalMask = 0;
    //Map trough results and calculate totals
    arr.map((e) => {
      totalRev = totalRev + e.totals.revenueConverted;
      totalCount = totalCount + e.totals.orderCount;
      totalCoupons = totalCoupons + e.totals.couponsUsed;
      totalComb = totalComb + e.totals.productTotals.comb;
      totalVital = totalVital + e.totals.productTotals.hairVital;
      totalMask = totalMask + e.totals.productTotals.mask;
    });

    return {
      rev: totalRev,
      count: totalCount,
      coupons: totalCoupons,
      products: {
        comb: totalComb,
        mask: totalMask,
        vital: totalVital,
      },
    };
  };

  useEffect(() => {
    if (!sessionStorage.getItem("accessToken")) {
      navigate("/");
    }
    fetchTotals();
  }, []);

  return (
    <div className="totals">
      <h1>TOTALS</h1>
      <div className="form-div">
        <h2>Statistika za</h2>
        <div className="period-choice">
          <span
            /* style={{ display: !dayDiv ? "block" : "none" }} */
            className={`${dayDiv ? "chosen" : ""}`}
            onClick={() => {
              toggleView();
            }}
          >
            Dan
          </span>
          {"  "}
          <span
            /* style={{ display: !periodDiv ? "block" : "none" }} */
            className={`${periodDiv ? "chosen" : ""}`}
            onClick={() => {
              toggleView();
            }}
          >
            Obdobje
          </span>
        </div>
        <div style={{ display: dayDiv ? "block" : "none" }}>
          <div className="select-ultra-container">
            <h2>Izberi dan</h2>
            <Select
              setDay={setDayStart}
              setMonth={setMonthStart}
              setYear={setYearStart}
            />
          </div>
        </div>
        <div
          className="period-div"
          style={{ display: periodDiv ? "flex" : "none" }}
        >
          <div className="select-ultra-container">
            <h2>Izberi od</h2>
            <Select
              setDay={setDayStart}
              setMonth={setMonthStart}
              setYear={setYearStart}
            />
          </div>
          <div className="select-ultra-container">
            <h2>Izberi do</h2>
            <Select
              setDay={setDayEnd}
              setMonth={setMonthEnd}
              setYear={setYearEnd}
            />
          </div>
        </div>
        <span
          className="btn"
          onClick={() => {
            fetchTotals();
          }}
        >
          Potrdi
        </span>
      </div>
      <p style={{ display: loading == true ? "block" : "none" }}>Loading...</p>
      <Table
        totals={totals}
        calculated={calculateTotals(totals)}
        display={displayTable}
      />
    </div>
  );
};

export default TotalsPage;
