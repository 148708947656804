import React from "react";

const Month = (props) => {
  const fillSelect = (length) => {
    const dnevi = Array.from({ length: length }, (x, i) => i + 1);

    const options = [];
    dnevi.map((e) => {
      options.push(<option value={e}>{e}</option>);
    });
    return options;
  };
  const onChange = (e) => {
    /* const oldState = {...props.state};
        oldState.month=e.target.value;
        props.setState(oldState); */
    props.setState(e.target.value);
  };
  return (
    <div className="select-container">
      <p className="select-label">Mesec</p>
      <select
        onChange={onChange}
        defaultValue={new Date(Date.now()).getMonth() + 1}
      >
        {fillSelect(12)}
      </select>
    </div>
  );
};

export default Month;
