import React, { useState, useEffect } from "react";

const CurrencyFrom = () => {
  const [currs, setCurrs] = useState([]);
  const [updateCurrName, setUpdateCurrName] = useState(null);
  const [updateCurrVal, setUpdateCurrVal] = useState(null);
  const [addCurrName, setAddCurrName] = useState(null);
  const [addCurrVal, setAddCurrVal] = useState(null);
  const [updateAlert, setUpdateAlert] = useState(null);
  const [addAlert, setAddAlert] = useState(null);

  const fetchCurrencies = async () => {
    try {
      const response = await fetch(
        "https://analitika.mpdiet.eu/api/valute" /* "http://localhost:5000/api/valute" */,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
          },
        }
      );
      const data = await response.json();
      console.log(data);
      setCurrs(data.result);
    } catch (error) {
      console.log(error);
    }
  };
  const renderData = (data) => {
    /* console.log(data); */
    const final = [];
    data.map((e) => {
      final.push(
        <tr>
          <td>{e.name}</td>
          <td>{e.rate}</td>
          <td>{e.last_edited.split("T")[0]}</td>
        </tr>
      );
    });
    return final;
  };

  const onChangeUpdateName = (e) => {
    setUpdateCurrName(e.target.value);
  };

  const onChangeUpdateVal = (e) => {
    setUpdateCurrVal(e.target.value);
  };

  const onChangeAddName = (e) => {
    setAddCurrName(e.target.value);
  };

  const onChangeAddVal = (e) => {
    setAddCurrVal(e.target.value);
  };
  const renderSelect = (data) => {
    const options = [];
    data.map((e) => {
      options.push(<option value={e.name}>{e.name}</option>);
    });
    return (
      <select onChange={onChangeUpdateName}>
        <option disabled={true} selected>
          Izberi
        </option>
        {options}
      </select>
    );
  };

  const updateCurrency = async () => {
    try {
      if (updateCurrName && updateCurrVal) {
        console.log(updateCurrName, updateCurrVal);

        const reqBody = { name: updateCurrName, rate: updateCurrVal };
        const response = await fetch(
          /* "http://localhost:5000/api/valute" */ "https://analitika.mpdiet.eu/api/valute",
          {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",

              Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
            },
            body: JSON.stringify(reqBody),
          }
        );
        const parsed = await response.json();
        if (parsed.success) {
          setUpdateAlert("Valuta uspesno spremenjena");
          fetchCurrencies();
        } else {
          setUpdateAlert("Prislo je do napake");
        }
      } else {
        setUpdateAlert("Vnesi vse podatke");
      }
    } catch (err) {
      setUpdateAlert("Prislo je do napake");
    }
  };

  const addCurrency = async () => {
    try {
      if (addCurrName && addCurrVal) {
        const reqBody = { name: addCurrName.toUpperCase(), rate: addCurrVal };
        const response = await fetch(
          /* "http://localhost:5000/api/valute" */ "https://analitika.mpdiet.eu/api/valute",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",

              Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
            },
            body: JSON.stringify(reqBody),
          }
        );
        const parsed = await response.json();
        if (parsed.success) {
          setAddAlert("Valuta uspesno dodana");
          fetchCurrencies();
        } else {
          setAddAlert("Prislo je do napake");
        }
      } else {
        setAddAlert("Vnesi vse podatke");
      }
    } catch (err) {
      setAddAlert("Prislo je do napake");
    }
  };

  useEffect(() => {
    fetchCurrencies();
  }, []);
  return (
    <div className="currency-form-div">
      {/************************CURRENCY TABLE********************************************/}
      <div className="c-form">
        <h1>Tecajnica</h1>
        <table className="currency-list">
          <thead>
            <tr>
              <td>Valuta</td>
              <td>Tecaj/EUR</td>
              <td>Datum</td>
            </tr>
          </thead>
          <tbody>{renderData(currs)}</tbody>
          <tfoot></tfoot>
        </table>

        {/************************UPDATE CURRENCY********************************************/}
        <h2>Posodobi tecaje</h2>
        <div className="">{updateAlert ? updateAlert : ""}</div>
        <table className="currency-form">
          <thead>
            <tr>
              <th>Valuta</th>
              <th>Tecaj</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{renderSelect(currs)}</td>
              <td>
                <input
                  type="number"
                  className="currency-input"
                  onChange={onChangeUpdateVal}
                ></input>
              </td>
              <td>
                <span className="btn" onClick={updateCurrency}>
                  Posodobi
                </span>
              </td>
            </tr>
          </tbody>
          <tfoot></tfoot>
        </table>
        {/************************ADD CURRENCY********************************************/}
        <h2>Dodaj valute</h2>
        <div className="">{addAlert ? addAlert : ""}</div>
        <table className="currency-form">
          <thead>
            <tr>
              <th>Valuta</th>
              <th>Tecaj</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <input
                  type="text"
                  className="currency-input"
                  onChange={onChangeAddName}
                ></input>
              </td>
              <td>
                <input
                  type="number"
                  className="currency-input"
                  onChange={onChangeAddVal}
                ></input>
              </td>
              <td>
                <span className="btn" onClick={addCurrency}>
                  Dodaj
                </span>
              </td>
            </tr>
          </tbody>
          <tfoot></tfoot>
        </table>
      </div>
    </div>
  );
};

export default CurrencyFrom;
