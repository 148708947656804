import React from 'react';
import Day from './day';
import Month from './month';
import Year from './year';
const Select = (props) => {
    return (
      <div className="select-super-container">
        <Day setState={props.setDay}  /* state={props.state} *//>
        <Month setState={props.setMonth} /* state={props.state} */ />
        <Year setState={props.setYear} /* state={props.state} *//>
      </div>
    );
  };
  
  export default Select;
  