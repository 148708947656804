import React, { useState } from "react";

const Row = (props) => {
  const [spend, setSpend] = useState(0);

  const handleChange = (e) => {
    setSpend(e.target.value);
  };
  return (
    <tr>
      <td>{props.store}</td>
      <td>{props.revenue.toFixed(2)} EUR</td>
      <td>{props.orders}</td>
      <td>{props.coupons}</td>
      <td>
        <input
          className="tbl-input-spend"
          type="number"
          onChange={handleChange}
        ></input>
      </td>
      <td>{(props.revenue / spend).toFixed(2)}</td>
      <td>{(spend / props.orders).toFixed(2)}</td>
      <td>{props.products.comb}</td>
      <td>
        {props.orders == 0
          ? 0
          : ((props.products.comb / props.orders) * 100).toFixed(2)}{" "}
        %
      </td>
      <td>{props.products.hairVital}</td>
      <td>{props.orders == 0
          ? 0
          : ((props.products.hairVital / props.orders) * 100).toFixed(2)} %</td>
      <td>{props.products.mask}</td>
      <td>{props.orders == 0
          ? 0
          : ((props.products.mask / props.orders) * 100).toFixed(2)} %</td>
    </tr>
  );
};

export default Row;
