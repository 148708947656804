import "./style/main.scss";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Navbar from "./components/navbar";
import AddStore from "./pages/addStore";
import Login from "./pages/login";
import StoreState from "./context/storeState";
import CurrencyFrom from "./pages/currencyFrom";
import TotalsPage from "./pages/totalsPage";
import AnaliticsPage from "./pages/analiticsPage";
import AnaliticsPageBeta from "./pages/analiticsPageBeta";
import CurrencyState from "./context/currency/currencyState";
function App() {
  return (
    <CurrencyState>
      <StoreState>
        <BrowserRouter>
          <div className="App">
            <Navbar />
            <Routes>
              <Route path="/" element={<Login />}></Route>
              <Route path="/valute" element={<CurrencyFrom />}></Route>
              <Route path="/add-store" element={<AddStore />}></Route>
              <Route path="/totals" element={<TotalsPage />}></Route>
              <Route path="/shop-stats" element={<AnaliticsPage />}></Route>
              <Route path="/beta-stats" element={<AnaliticsPageBeta />}></Route>
              {/* <Route path="*">
                <h1>Not the page you are loking for.</h1>
              </Route> */}
            </Routes>
          </div>
        </BrowserRouter>
      </StoreState>
    </CurrencyState>
  );
}

export default App;
