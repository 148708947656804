import React, { useState } from "react";

const SumRow = (props) => {
  const [spend, setSpend] = useState(0);
  const handleChange = (e) => {
    setSpend(e.target.value);
  };
  return (
    <tr>
      <th>SKUPAJ</th>
      <th>{props.rev.toFixed(2)} EUR</th>
      <th>{props.count}</th>
      <th>{props.coupons}</th>
      <td>
        <input
          className="tbl-input-spend"
          type="number"
          onChange={handleChange}
        ></input>
      </td>
      <td>{(props.rev / spend).toFixed(2)}</td>
      <td>{(spend / props.count).toFixed(2)}</td>
      <td>{props.products.comb}</td>
      <td>
        {props.count == 0
          ? 0
          : ((props.products.comb / props.count) * 100).toFixed(2)}{" "}
        %
      </td>
      <td>{props.products.vital}</td>
      <td>
        {props.count == 0
          ? 0
          : ((props.products.vital / props.count) * 100).toFixed(2)}{" "}
        %
      </td>
      <td>{props.products.mask}</td>
      <td>
        {props.count == 0
          ? 0
          : ((props.products.mask / props.count) * 100).toFixed(2)}{" "}
        %
      </td>
    </tr>
  );
};

export default SumRow;
