import React, { useState, useContext, useEffect } from "react";
import CurrencyContext from "../context/currency/currencyContext";
import StoreContext from "../context/storeContext";
import Select from "../components/selectbox/selectb";
import { useNavigate } from "react-router-dom";

import Campaign from "../components/table/campaignBeta";
const AnaliticsPageBeta = () => {
  const initState = (a) => {
    const today = new Date(Date.now());
    const state = {};
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    const day = today.getDate();
    switch (a) {
      case "D":
        return day;
      case "M":
        return month;
      case "Y":
        return year;
    }
    /* return state; */
  };
  /******************DISPLAY STATE*******************************************************/
  const [dayDiv, setDayDiv] = useState(true);
  const [periodDiv, setPeriodDiv] = useState(false);
  const [displayTable, setDisplayTable] = useState(false);
  const [loading, setLoading] = useState(true);
  const [resultNum, setResultNum] = useState(null);
  const [result, setResult] = useState([]);
  /****************SHOP TOTALS**************************************************************************** */
  const [ordersNum, setOrdersNum] = useState(null);
  const [ordersSum, setOrdersSum] = useState(null);
  const [productTotals, setProductTotals] = useState({
    comb: 0,
    hairVital: 0,
    hairMask: 0,
  });
  const navigate = useNavigate();
  /************************************************************************************/

  /*********************SELECT START***************************************/
  const [endDateSelected, setEndDateSelected] = useState(false);
  const [dayStart, setDayStart] = useState(initState("D"));
  const [monthStart, setMonthStart] = useState(initState("M"));
  const [yearStart, setYearStart] = useState(initState("Y"));
  /***************************SELECT END************************************************/
  const [dayEnd, setDayEnd] = useState(initState("D"));
  const [monthEnd, setMonthEnd] = useState(initState("M"));
  const [yearEnd, setYearEnd] = useState(initState("Y"));
  /**************************************************************************/
  const toggleView = () => {
    setDayDiv(!dayDiv);
    setPeriodDiv(!periodDiv);
    setEndDateSelected(!endDateSelected);
  };

  const currencyContext = useContext(CurrencyContext);
  const storeContext = useContext(StoreContext);

  const getCurrentRate = () => {
    const selected = storeContext.state.currency;
    const rates = currencyContext.state.currencies;
    let rate = 1;
    rates.map((e) => {
      if (e.name == selected) {
        rate = e.rate;
      }
    });
    return rate;
  };
  const fetchPeriod = async () => {
    try {
      setResult([]);
      setLoading(true);
      let perStart = `${yearStart}-${
        monthStart.lengt > 1 ? monthStart : "0" + monthStart
      }-${dayStart.lengt > 1 ? dayStart : "0" + dayStart}`;
      let perEnd = `${yearEnd}-${
        monthEnd.lengt > 1 ? monthEnd : "0" + monthEnd
      }-${dayEnd.lengt > 1 ? dayEnd : "0" + dayEnd}`;
      if (!endDateSelected) {
        perEnd = perStart;
      }
      const response = await fetch(
        /* `http://localhost:5000/api/orders/period-sorted?start=${perStart}&end=${perEnd}&store=${storeContext.state.selectedStore}`, */
        `https://analitika.dex-lab.eu/test-2?start=${perStart}&end=${perEnd}&store=${storeContext.state.selectedStore}`,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
          },
        }
      );

      const data = await response.json();

      if (data.sucess == false) {
        setResult([]);
        setResultNum(0);
        setLoading(false);
        setDisplayTable(false);
        setOrdersNum(0);
        setOrdersSum(null);
        setProductTotals({ comb: 0, hairVital: 0, hairMask: 0 });
        return;
      }
      if (data.final.length > 0) {
        const filtered = data.final;
        setOrdersNum(data.ordersCount);
        setOrdersSum(data.ordersSum);
        setProductTotals(data.productTotals);
        setResult(filtered);
        setResultNum(filtered.length);
        setDisplayTable(true);
      } else {
        setResult([]);
        setResultNum(null);
        setDisplayTable(false);
        setOrdersNum(0);
        setOrdersSum(null);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const renderCampaigns = () => {
    const camps = [];
    result.map((e) => {
      camps.push(
        <Campaign
          campaign={e.campaign}
          adsets={e.adsets}
          display={displayTable}
          currency={"EUR"}
          rate={getCurrentRate()}
        />
      );
    });
    return camps;
  };

  useEffect(() => {
    if (!sessionStorage.getItem("accessToken")) {
      navigate("/");
    }
    fetchPeriod();
  }, []);

  return (
    <div>
      <h1>ANALITIKA PO TRGOVINAH</h1>
      <div className="form-div">
        <h2>Statistika za</h2>
        <div className="period-choice">
          <span
            /* style={{ display: !dayDiv ? "block" : "none" }} */
            className={`${dayDiv ? "chosen" : ""}`}
            onClick={() => {
              toggleView();
            }}
          >
            Dan
          </span>
          {"  "}
          <span
            /* style={{ display: !periodDiv ? "block" : "none" }} */
            className={`${periodDiv ? "chosen" : ""}`}
            onClick={() => {
              toggleView();
            }}
          >
            Obdobje
          </span>
        </div>
        <div style={{ display: dayDiv ? "block" : "none" }}>
          <div className="select-ultra-container">
            <h2>Izberi dan</h2>
            <Select
              setDay={setDayStart}
              setMonth={setMonthStart}
              setYear={setYearStart}
            />
          </div>
        </div>
        <div
          className="period-div"
          style={{ display: periodDiv ? "flex" : "none" }}
        >
          <div className="select-ultra-container">
            <h2>Izberi od</h2>
            <Select
              setDay={setDayStart}
              setMonth={setMonthStart}
              setYear={setYearStart}
            />
          </div>
          <div className="select-ultra-container">
            <h2>Izberi do</h2>
            <Select
              setDay={setDayEnd}
              setMonth={setMonthEnd}
              setYear={setYearEnd}
            />
          </div>
        </div>
        <span
          className="btn"
          onClick={() => {
            fetchPeriod();
          }}
        >
          Potrdi
        </span>
      </div>
      <p style={{ display: loading == true ? "block" : "none" }}>Loading...</p>
      <table
        style={{
          margin: "0 35%",
          display: !displayTable ? "none" : "block",
        }}
      >
        <thead>
          <tr>
            <th>Skupno</th>
            <th>Narocila</th>
            <th>Vrednost</th>
            <th>Comb</th>
            <th>C %</th>
            <th>HairVital</th>
            <th>HV %</th>
            <th>HairMask</th>
            <th>HM %</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td></td>
            <td>{ordersNum}</td>
            <td>{(ordersSum / getCurrentRate()).toFixed(2)} EUR</td>
            <td>{productTotals.comb}</td>
            <td>
              {ordersNum == 0
                ? 0
                : ((productTotals.comb / ordersNum) * 100).toFixed(2)}{" "}
              %
            </td>
            <td>{productTotals.hairVital}</td>
            <td>
              {ordersNum == 0
                ? 0
                : ((productTotals.hairVital / ordersNum) * 100).toFixed(2)}{" "}
              %
            </td>
            <td>{productTotals.hairMask}</td>
            <td>
              {ordersNum == 0
                ? 0
                : ((productTotals.hairMask / ordersNum) * 100).toFixed(2)}{" "}
              %
            </td>
          </tr>
        </tbody>
        <tfoot></tfoot>
      </table>
      {renderCampaigns()}
      <p
        style={{
          display: resultNum > 0 || resultNum == null ? "none" : "block",
        }}
      >
        Ni rezultatov
      </p>
    </div>
  );
};

export default AnaliticsPageBeta;
